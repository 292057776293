import { defineStore } from 'pinia'
import { ref } from 'vue'
import { notification } from 'ant-design-vue'
import { KAMForm } from '@/types/kam'
import KAMFormModel from '@/models/KAMFormModel'
import TableSettings from '@/types/tableSettings'
import { WrappedResponse } from 'vue-api-query'
import { ClosedKAMFormStatuses, OpenKAMFormStatuses } from '@/helpers/kamFormStates'

export const kamStore = defineStore('kam', () => {
  const loading = ref({ list: false, item: false, saving: false })

  const kamForms = ref<WrappedResponse<KAMFormModel[]>|KAMFormModel[]>({
    data: [],
    meta: {
      per_page: 25,
      total: 0
    }
  })

  const kamForm = ref<KAMForm|null>(null)

  function getKAMForms (tableSettings:TableSettings) : void {
    loading.value.list = true
    let kamFormModel = new KAMFormModel()

    // Add filters if there are any filters set.
    if (tableSettings.activeFilters) {
      Object.keys(tableSettings.activeFilters).forEach(key => {
        if (tableSettings.activeFilters[key].length) {
          kamFormModel = kamFormModel.whereIn(key, tableSettings.activeFilters[key])
        }
      })
    }

    // Check if only archived surveys have to be shown.
    if (tableSettings.archived) {
      kamFormModel = kamFormModel.whereIn('status', tableSettings.activeFilters?.status || ClosedKAMFormStatuses)
    } else {
      kamFormModel = kamFormModel.whereIn('status', tableSettings.activeFilters?.status || OpenKAMFormStatuses)
    }

    // Add search if available.
    if (tableSettings.search) {
      kamFormModel = kamFormModel.where('search', tableSettings.search)
    }

    // Add orderBy if sort is set.
    if (tableSettings.sort && tableSettings.sort.order && tableSettings.sort.columnKey) {
      kamFormModel = kamFormModel.orderBy(tableSettings.sort.order === 'ascend' ? tableSettings.sort.columnKey : '-' + tableSettings.sort.columnKey)
    }

    if (tableSettings.pagination) {
      kamFormModel = kamFormModel.limit(tableSettings.pagination.pageSize).page(tableSettings.pagination.current)
    }

    kamFormModel.get().then((r) => {
      kamForms.value = r
    }).catch(() => {
      notification.error({ message: 'Fout tijdens het ophalen van de KAM-formulieren!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
    }).finally(() => {
      loading.value.list = false
    })
  }

  return { kamForms, kamForm, loading, getKAMForms }
})
