export const Levels = [
  {
    id: -3,
    name: 'Verdieping -3'
  },
  {
    id: -2,
    name: 'Verdieping -2'
  },
  {
    id: -1,
    name: 'Verdieping -1'
  },
  {
    id: 0,
    name: 'Begane grond'
  },
  {
    id: 1,
    name: '1e verdieping'
  },
  {
    id: 2,
    name: '2e verdieping'
  },
  {
    id: 3,
    name: '3e verdieping'
  },
  {
    id: 4,
    name: '4e verdieping'
  },
  {
    id: 5,
    name: '5e verdieping'
  },
  {
    id: 6,
    name: '6e verdieping'
  },
  {
    id: 7,
    name: '7e verdieping'
  },
  {
    id: 8,
    name: '8e verdieping'
  },
  {
    id: 9,
    name: '9e verdieping'
  },
  {
    id: 10,
    name: '10e verdieping'
  }
]

export const dateTimeOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: undefined, // Ensure seconds are not included
  hour12: false // Use 24-hour time
}
